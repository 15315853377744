<template>
  <Navbar :user="user" />
    <div class="container pb-100 pt-100" v-if="!isLoading">
      <div class="section-title">
        <h2>How do you want your course title to be?</h2>
      </div>
      <div class="contact-form">
        <form id="contactForm" @submit.prevent="editCourse">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Name</label>
                <input type="text" v-model="form.name" id="name" class="form-control"/>
                <div v-if="errors.name" class="text-small text-danger text-start">{{ errors.name[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <button type="submit" class="btn default-btn" disabled v-if="submitting">
                <span class="label">{{ value }}</span>
              </button>
              <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
                <span class="label">Create Course</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import axios from 'axios'
import Loading from '@/components/Loading.vue';
export default {
  components: { Navbar,Loading },
  data(){
    return {
      isLoading: true,
      submitting: false,
      token: localStorage.getItem('user_data'),
      form:{
        title:'', course_category_id:'', sub_course_category_id:'', subject_id:'',
      },
      user: {},
      editableSubCat: false,
      categories: {},
      sub_categories: {},
      subjects: {},
      errors: {}
    }
  },
  methods:{
    editCourse(){
      this.submitting = true
      this.value = 'Please Wait...'
      let id = this.$route.params.id
      axios.post('https://apitraining.vipawaworks.com/api/course/update/' + id,this.form).then(response =>{
        response.data
        this.$router.push({ name: 'CourseDashboard', params: {id: id}})
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.submitting = false
      })
    },
    getClassRoom(){
        let id = this.$route.params.id
        axios.get('https://apitraining.vipawaworks.com/api/course/profile/' + id).then(response => {
            this.form = response.data
        }).catch(errors => {
            this.errors = errors.response.data.errors
        })
    },

    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Course - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
    this.getClassRoom()    
  }
};
</script>

<style>
</style>